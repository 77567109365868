.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.5rem;
    --bs-pagination-font-size: 0.8125rem;
    --bs-pagination-color: var(--bs-gray-600);
    --bs-pagination-bg: #e0e0e0;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: var(--bs-gray-400);
    --bs-pagination-border-radius: 0.25rem;
    --bs-pagination-hover-color: #4458b8;
    --bs-pagination-hover-bg: var(--bs-gray-200);
    --bs-pagination-hover-border-color: var(--bs-gray-400);
    --bs-pagination-focus-color: #4458b8;
    --bs-pagination-focus-bg: var(--bs-gray-200);
    --bs-pagination-focus-box-shadow: 0 0 0 0.15rem rgba(85, 110, 230, 0.25);
    --bs-pagination-active-color: #e0e0e0;
    --bs-pagination-active-bg: #556ee6;
    --bs-pagination-active-border-color: #556ee6;
    --bs-pagination-disabled-color: var(--bs-gray-400);
    --bs-pagination-disabled-bg: #e0e0e0;
    --bs-pagination-disabled-border-color: var(--bs-gray-400);
    --bs-card-title-color: #e0e0e0 !important;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0;
    list-style: none;
}

.pagination-rounded .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}

.btn-soft-danger {
    color: #f46a6a;
    background-color: rgba(244, 106, 106, 0.1);
    border-color: transparent;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.btn-soft-primary {
    color: #50a5f1;
    background-color: rgba(80, 165, 241, 0.1);
    border-color: transparent;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

@media (max-width: 380px) {
    .navbar-brand-box {
        display: block;
    }
}
